.footer {
  margin-top: auto;
  background: white;
  padding: 15px 40px;

  p {
    font-size: 14px;
    margin-bottom: 10px;

    @media (min-width: 1024px) {
      margin: 0;
    }
  }

  a {
    font-size: 15px;
    margin-right: 70px;
    text-decoration: none;
    color: black;

    &:last-child {
      margin: 0;
    }
  }
}
