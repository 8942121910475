.check-box-wrapper {
  width: fit-content;

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .check-box {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 1px solid #4f4f4f;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    svg {
      width: 80%;
      height: 80%;
      fill: rgb(0, 119, 160);
    }
  }

  p {
    font-size: 16px;
    color: #495057;
    margin-left: 18px;
  }
}
