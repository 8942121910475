body {
  margin: 0;
  font-family: "Inter", Sans-serif;
}

main {
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.main-body {
  padding-top: 66px;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #7fcfea;
  border-radius: 5px;
}

.lg-body {
  max-width: 1300px;
  padding: 0 15px;

  @media (min-width: 1024px) {
    padding: 0 30px;
  }
}

.mx-auto {
  margin: 0 auto;
}

// global styles
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.items-sm-start {
  @media (max-width: 1023px) {
    align-items: flex-start;
  }
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-sm-start {
  @media (max-width: 1023px) {
    justify-content: flex-start;
  }
}

.flex-sm-col {
  @media (max-width: 1023px) {
    flex-direction: column;
  }
}

.flex-1 {
  flex: 1;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.lg-mx-auto {
  max-width: 1440px;
  margin: 0 auto;
}

.uppercase {
  text-transform: uppercase;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 1023px) {
  .show-web {
    display: none;
  }
}

@media (min-width: 1024px) {
  .show-mobile {
    display: none;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 5px;
}

.button-primary {
  border: 1px solid #4f4f4f;
  border-radius: 5px;
  background: white;
  color: #055b7a;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);

  &:hover {
    background: rgb(20, 96, 122);
    border-color: rgb(5, 91, 122);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px, rgba(0, 0, 0, 0.25) 0px 4px 4px,
      rgba(0, 0, 0, 0.25) 0px 4px 4px;

    color: white;
    span {
      color: white;
    }
    svg {
      fill: white;
      path {
        fill: white;
      }
    }
  }
}
