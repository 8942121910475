.search-input-wrapper {
  min-width: 200px;

  .search-input {
    height: 40px;
    border: 2px solid #4f4f4f;
    border-radius: 10px;
    padding: 0 21px 0 19px;

    div {
      align-self: center;
      height: calc(100% - 5px);
    }

    input {
      margin: 0 10px 0 17px;
      font-size: 16px;
      border: none;
      outline: none;
      box-shadow: none;
      color: black;

      &::placeholder {
        color: #4f4f4f;
      }
    }
  }

  .search-results {
    border: 2px solid #4f4f4f;
    max-height: 300px;
    min-height: 35px;
    overflow-y: auto;
    left: 0;
    top: 40px;
    right: 0;
    display: none;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: white;
    z-index: 20;

    .search-option {
      height: 35px;
      padding: 0 21px 0 19px;
      font-size: 14px;

      &:hover {
        background: rgba(127, 207, 234, 0.15);
      }
    }
  }

  &.active {
    .search-input {
      border-color: rgb(0, 119, 160);
    }

    .search-results {
      display: block;
      border-color: rgb(0, 119, 160);
    }
  }

  &.is-open {
    .search-input {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      div {
        border-bottom: 2px solid rgb(0, 119, 160);
      }
    }

    svg {
      &:last-of-type {
        transform: rotate(180deg);
      }
    }
  }
}
