.header {
  height: 66px;
  background: #f0f0f0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 200;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2))
    drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));

  .logo {
    width: 100px;
    margin: 0 100px;
  }

  .header-links {
    width: calc(50% - 150px);

    img {
      width: 40px;
      height: 40px;
    }
  }
}
