.home-page {
  padding-top: 35px;
  padding-bottom: 35px;

  h1 {
    font-size: 40px;
    margin-bottom: 47px;
    font-weight: 400;
  }

  .home__wrapper {
    margin-bottom: 50px;
  }

  .home__wrapper_header {
    padding-bottom: 10px;
    border-bottom: 3px solid #0077a0;
    margin-bottom: 14px;

    .button {
      padding: 0;
      width: 40px;
      height: 40px;
    }

    &.button_header {
      padding: 5px 0;
    }

    .home__wrapper_title {
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  .home__wrapper_content {
    p {
      font-size: 16px;
      line-height: 32px;
      color: #495057;
    }
  }

  .home__search_wrapper {
    @media (min-width: 1024px) {
      width: 420px;
      margin-right: 100px;
    }

    .home__patient_search {
      width: 100%;
      margin: 15px 0 30px;
    }

    .home__select_container {
      margin-top: 20px;

      .home__select_header {
        p {
          margin-left: 15px;
          color: #4f4f4f;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .home__select_box {
        width: 100%;

        @media (min-width: 1024px) {
          width: 226px;
        }
      }
    }

    .home__fatal_checkbox {
      margin: 23px 0;
    }

    .home__year_wrapper {
      margin-bottom: 50px;
      p {
        font-size: 16px;
        color: #495057;
        margin-right: 15px;
      }
      input {
        width: 114px;
        height: 30px;
        margin-right: 15px;
        border-radius: 5px;
        border: 1px solid #595959;
        font-size: 16px;
        color: black;
        padding: 0 9px;

        &::placeholder {
          color: #495057;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }

    .home__search_btn {
      width: 117px;
      height: 44px;
      margin-right: 15px;
      color: #055b7a;

      &:last-of-type {
        margin-right: 0;
      }

      span {
        margin-left: 15px;
        font-size: 16px;
      }
    }
  }

  .home__result_wrapper {
    .home__result_container {
      height: 288px;
      overflow-y: auto;
      background: white;
      border: 1px solid #d6d6d6;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 8px 0 13px;

      &.disabled {
        opacity: 0.7;
        pointer-events: none;
      }

      .home__result_item {
        padding: 0 26px;
        background: white;
        font-size: 14px;
        height: 48px;

        &:nth-of-type(2n) {
          background: #f1f3f5;
        }

        &:hover {
          background: rgba(127, 207, 234, 0.15);
        }

        &.active {
          background: rgba(127, 207, 234, 0.15);
          font-weight: 600;
        }
      }
    }
  }

  .home__info_wrapper {
    .home__info_content {
      padding: 30px 25px;
      border: 1px solid #d6d6d6;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      min-height: 150px;

      .spinner {
        margin: auto;
      }

      .home__patient_photo {
        width: 252px;
        height: 235px;
        margin-right: 32px;

        .lazyload-wrapper {
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: contain;
        }
      }

      .home__info_block {
        margin-bottom: 15px;
        p {
          font-size: 14px;
          line-height: 20px;
          &:first-child {
            font-weight: 700;
          }

          strong {
            font-weight: 400;
          }

          span {
            display: block;
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}
